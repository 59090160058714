import React, {useContext, useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import styled from '@emotion/styled';
import * as R from 'ramda';

import {AppContext} from '../../../../../../context/AppContext';
import {
  formatDollar,
  formatPercent,
  formatMoc,
  formatDollarsInMillionsWithDecimal,
  formatDistCCGraphXAxisAssetCard
} from '../../../../../../utils';
import * as COLORS from '../../../../../../theme/colors';
import LineGraph from '../../../../../../components/LineGraph';
import DonutGraph from '../../../../../../components/DonutGraph';
import {assetClassLocations} from '../../assetClassLocations';
import DistCCGraph from '../../../../../../components/DistCCGraph/DistCCGraphComponent';
import {NA, setRenderError} from '../../../../../../utils/errorHelpers';
import logger from '../../../../../../logger';

const AssetClassCardContainer = styled.div`
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  background-color: ${COLORS['@component-background']};
  transition: box-shadow 300ms;
  border-radius: 5px;
  padding: 30px;
  height: 95%;
  max-width: 100%;
  color: ${COLORS['@body-background']};
  & h4,
  h2,
  .body-medium {
    margin: 0px;
    padding-bottom: 5px;
  }
  & .card-text {
    color: ${COLORS['@neutrals-9']};
    & .text-spacing {
      word-spacing: 5px;
    }
  }
  &:hover {
    box-shadow: 0px 10px 25px rgba(36, 111, 201, 0.35);
    cursor: pointer;
  }
  & .allocation-donut-graph {
    width: 120px;
    height: 90px;
    float: right;
    & .recharts-surface {
      width: 130px;
      height: 100px;
      padding-right: 80px;
      padding-bottom: 10px;
      overflow: visible;
    }
  }
  & .more-info-link {
    line-height: 40px;
    width: 50px;
    outline: none;
  }
  & .more-info-link:hover {
    color: ${COLORS['@secondary-blue']};
  }
`;

const StyledCashAccount = styled.div`
  max-width: 100%;
  padding-top: 5px;
  & .row {
    display: flex;
    justify-content: space-between;
    & .cash-card__marketValue {
      width: 110px;
    }
  }
  & .cash-card__accounts {
    line-height: 24px;
    margin-bottom: 16px;
  }
  & .cash-card__marketValue {
    line-height: 24px;
    margin-bottom: 16px;
  }
`;

const StyledNetflowsLabel = styled.div`
  padding-top: 5px;
  padding-right: 25px;
  margin-bottom: 10px;
  text-align: right;
`;

const StyledNetflows = styled.span``;

const AssetClassCard = ({assetCardData, assetCardGraphData, testingWidth, testingInception}) => {
  logger.trace({
    method: 'AssetClassCard/assetCardData',
    assetCardData
  });
  logger.trace({
    method: 'AssetClassCard/assetCardGraphData',
    assetCardGraphData
  });
  const history = useHistory();
  const sortData = data => {
    return R.isNil(data) ? [] : R.sort((a, b) => a.date - b.date)(data);
  };
  // Sort the report date to ensure proper date sequence
  // eslint-disable-next-line prettier/prettier
  const sortedAssetCardReportData = useMemo(
    () => sortData(assetCardData.report?.data),
    [assetCardData]
  );

  const [hoverStyle, setHoverStyle] = useState({display: 'none'});

  const {state, dispatch} = useContext(AppContext);
  const {activePeriod, clientInfo} = state;
  const activeEntity = state.selectedEntityId;
  const activePortfolio = R.find(R.propEq('entity', activeEntity))(
    state.clientInfo?.portfolios || []
  );
  const clientInceptionDate = testingInception || activePortfolio?.quickSummary?.inceptionDate;

  const page = 'portfolioPage';

  const cashAccounts = accounts => {
    const orderedAccounts = R.sort((a, b) => b?.marketValue - a?.marketValue, accounts);
    if (accounts.length < 5) {
      return orderedAccounts.map(card => (
        <div className="body-large-bold row" key={card?.displayName}>
          <div className="cash-card__accounts">{card?.displayName}</div>
          <div className="cash-card__marketValue">{formatDollar(card?.netflows, true, true)}</div>
        </div>
      ));
    }
    return orderedAccounts.slice(0, 3).map(card => (
      <div className="body-large-bold row" key={card?.displayName}>
        <div className="cash-card__accounts">{card?.displayName}</div>
        <div className="cash-card__marketValue">{formatDollar(card?.netflows, true, true)}</div>
      </div>
    ));
  };

  const redirectToClassPage = () => {
    if (!assetClassLocations[assetCardData?.assetClass]) {
      setRenderError(
        dispatch,
        `[${page}:AssetClassCardContainer] ${assetCardData?.assetClass} page not available`
      );
      return;
    }
    history.push(assetClassLocations[assetCardData.assetClass]);
  };

  switch (assetCardData?.class) {
    case 'Cash':
      return (
        <AssetClassCardContainer
          className="asset-class-card"
          onClick={redirectToClassPage}
          onMouseEnter={() => {
            setHoverStyle({
              display: 'block'
            });
          }}
          onMouseLeave={() => {
            setHoverStyle({display: 'none'});
          }}
        >
          <div className="allocation-donut-graph" style={hoverStyle}>
            <DonutGraph
              assetAllocationData={assetCardGraphData}
              graphType="asset-card-donut-graph"
              assetClassName={assetCardData?.assetClass}
            />
          </div>
          <h4 data-asset-class={assetCardData?.assetClass}>{assetCardData?.class}</h4>
          <h2>{formatDollar(assetCardData?.marketValue)}</h2>
          <div className="body-medium-bold card-text">
            Netflows{' '}
            <StyledNetflows>{formatDollar(assetCardData?.netflows, true, true)}</StyledNetflows>
            <span className="body-large-bold">
              <StyledNetflowsLabel>Netflows</StyledNetflowsLabel>
            </span>
            <StyledCashAccount>{cashAccounts(assetCardData?.accounts ?? [])}</StyledCashAccount>
            {assetCardData?.accounts?.length > 4 ? (
              <div
                role="button"
                tabIndex="0"
                className="more-info-link body-large-bold"
                onClick={redirectToClassPage}
                onKeyDown={() => {}}
              >
                More...
              </div>
            ) : null}
          </div>
        </AssetClassCardContainer>
      );
    case 'Fixed Income':
      return (
        <AssetClassCardContainer
          className="asset-class-card"
          onClick={redirectToClassPage}
          onMouseEnter={() => {
            setHoverStyle({
              display: 'block'
            });
          }}
          onMouseLeave={() => {
            setHoverStyle({display: 'none'});
          }}
        >
          <div className="allocation-donut-graph" style={hoverStyle}>
            <DonutGraph
              assetAllocationData={assetCardGraphData}
              graphType="asset-card-donut-graph"
              assetClassName={assetCardData?.assetClass}
            />
          </div>
          <h4 data-asset-class={assetCardData?.assetClass}>{assetCardData?.class}</h4>
          <h2>{formatDollar(assetCardData?.marketValue)}</h2>
          <div className="body-medium-bold card-text">
            Netflows{' '}
            <StyledNetflows>{formatDollar(assetCardData?.netflows, true, true)}</StyledNetflows>
            {assetCardData?.report === null ? (
              ''
            ) : (
              <div data-testid={`${assetCardData?.assetClass}-cardgraph`}>
                <LineGraph
                  reportData={assetCardData?.report?.data}
                  type="line-graph"
                  testingInception={testingInception}
                />
              </div>
            )}
          </div>
        </AssetClassCardContainer>
      );
    case 'Balanced Accounts':
      return (
        <AssetClassCardContainer
          className="asset-class-card"
          onClick={redirectToClassPage}
          onMouseEnter={() => {
            setHoverStyle({
              display: 'block'
            });
          }}
          onMouseLeave={() => {
            setHoverStyle({display: 'none'});
          }}
        >
          <div className="allocation-donut-graph" style={hoverStyle}>
            <DonutGraph
              assetAllocationData={assetCardGraphData}
              graphType="asset-card-donut-graph"
              assetClassName={assetCardData?.assetClass}
            />
          </div>
          <h4 data-asset-class={assetCardData?.assetClass}>{assetCardData?.class}</h4>
          <h2>{formatDollar(assetCardData?.marketValue)}</h2>
          <div className="body-medium-bold card-text">
            <div className="text-spacing">
              {formatPercent(assetCardData?.gainsPercent, true)}{' '}
              {formatDollar(assetCardData?.gains, true)}
            </div>
          </div>
          {assetCardData?.report === null ? (
            ''
          ) : (
            <div data-testid={`${assetCardData?.assetClass}-cardgraph`}>
              <LineGraph
                reportData={assetCardData?.report?.data}
                type="line-graph"
                testingInception={testingInception}
              />
            </div>
          )}
        </AssetClassCardContainer>
      );
    case 'Equities':
      return (
        <AssetClassCardContainer
          className="asset-class-card"
          onClick={redirectToClassPage}
          onMouseEnter={() => {
            setHoverStyle({
              display: 'block'
            });
          }}
          onMouseLeave={() => {
            setHoverStyle({display: 'none'});
          }}
        >
          <div className="allocation-donut-graph" style={hoverStyle}>
            <DonutGraph
              assetAllocationData={assetCardGraphData}
              graphType="asset-card-donut-graph"
              assetClassName={assetCardData?.assetClass}
            />
          </div>
          <h4 data-asset-class={assetCardData?.assetClass}>{assetCardData?.class}</h4>
          <h2>{formatDollar(assetCardData?.marketValue)}</h2>
          <div className="body-medium-bold card-text">
            <div className="text-spacing">
              {formatPercent(assetCardData?.gainsPercent, true)}{' '}
              {formatDollar(assetCardData?.gains, true)}
            </div>
          </div>
          {assetCardData.report === null ? (
            ''
          ) : (
            <div data-testid={`${assetCardData?.assetClass}-cardgraph`}>
              <LineGraph
                reportData={assetCardData?.report?.data}
                type="line-graph"
                testingInception={testingInception}
              />
            </div>
          )}
        </AssetClassCardContainer>
      );
    case 'Alternative Assets':
      return (
        <AssetClassCardContainer
          className="asset-class-card"
          onClick={redirectToClassPage}
          onMouseEnter={() => {
            setHoverStyle({
              display: 'block'
            });
          }}
          onMouseLeave={() => {
            setHoverStyle({display: 'none'});
          }}
        >
          <div className="allocation-donut-graph" style={hoverStyle}>
            <DonutGraph
              assetAllocationData={assetCardGraphData}
              graphType="asset-card-donut-graph"
              assetClassName={assetCardData?.assetClass}
            />
          </div>
          <h4 data-asset-class={assetCardData?.assetClass}>{assetCardData?.class}</h4>
          <h2>{formatDollar(assetCardData?.marketValue)}</h2>
          <div className="body-medium-bold card-text">
            <div className="text-spacing">
              {formatPercent(assetCardData?.gainsPercent, true)}{' '}
              {formatDollar(assetCardData?.gains, true)}
            </div>
          </div>
          {assetCardData.report === null ? (
            ''
          ) : (
            <div data-testid={`${assetCardData?.assetClass}-cardgraph`}>
              <LineGraph
                reportData={assetCardData?.report?.data}
                type="line-graph"
                testingInception={testingInception}
              />
            </div>
          )}
        </AssetClassCardContainer>
      );
    case 'Other Investments':
      return (
        <AssetClassCardContainer
          className="asset-class-card"
          onClick={redirectToClassPage}
          onMouseEnter={() => {
            setHoverStyle({
              display: 'block'
            });
          }}
          onMouseLeave={() => {
            setHoverStyle({display: 'none'});
          }}
        >
          <div className="allocation-donut-graph" style={hoverStyle}>
            <DonutGraph
              assetAllocationData={assetCardGraphData}
              graphType="asset-card-donut-graph"
              assetClassName={assetCardData?.assetClass}
            />
          </div>
          <h4 data-asset-class={assetCardData?.assetClass}>{assetCardData?.class}</h4>
          <h2>{formatDollar(assetCardData?.marketValue)}</h2>
          <div className="body-medium-bold card-text">
            <div className="text-spacing">
              {(assetCardData?.gainsPercent === null || assetCardData?.gainsPercent === NA) &&
              assetCardData?.gains === 0 ? (
                <>{NA}</>
              ) : (
                <>
                  {formatPercent(assetCardData?.gainsPercent, true)}{' '}
                  {formatDollar(assetCardData?.gains, true)}
                </>
              )}
            </div>
          </div>
          {assetCardData.report === null ? (
            ''
          ) : (
            <div data-testid={`${assetCardData?.assetClass}-cardgraph`}>
              <LineGraph
                reportData={assetCardData?.report?.data}
                type="line-graph"
                testingInception={testingInception}
              />
            </div>
          )}
        </AssetClassCardContainer>
      );
    case 'Hybrid Investments':
      return (
        <AssetClassCardContainer
          className="asset-class-card"
          onClick={redirectToClassPage}
          onMouseEnter={() => {
            setHoverStyle({
              display: 'block'
            });
          }}
          onMouseLeave={() => {
            setHoverStyle({display: 'none'});
          }}
        >
          <div className="allocation-donut-graph" style={hoverStyle}>
            <DonutGraph
              assetAllocationData={assetCardGraphData}
              graphType="asset-card-donut-graph"
              assetClassName={assetCardData?.assetClass}
            />
          </div>
          <h4 data-asset-class={assetCardData?.assetClass}>{assetCardData?.class}</h4>
          <h2>{formatDollar(assetCardData?.marketValue)}</h2>
          <div className="body-medium-bold card-text">
            IRR: {formatPercent(assetCardData?.irr, true)} / MOC: {formatMoc(assetCardData?.moc)}
            <br />
            Unfunded: {formatDollarsInMillionsWithDecimal(assetCardData?.totalFundingUnfunded)}
            {R.isNil(assetCardData?.report?.data) || R.isEmpty(assetCardData?.report?.data) ? (
              NA
            ) : (
              <div data-testid={`${assetCardData?.assetClass}-cardgraph`}>
                <DistCCGraph
                  reportData={formatDistCCGraphXAxisAssetCard(
                    sortedAssetCardReportData,
                    activePeriod,
                    clientInceptionDate,
                    clientInfo.selectedAsOfDate
                  )}
                  testingWidth={testingWidth}
                  type="assetCard-graph"
                  displayMarketValue={false}
                  period={activePeriod}
                />
              </div>
            )}
          </div>
        </AssetClassCardContainer>
      );
    case 'Multi-Asset Investments':
      return (
        <AssetClassCardContainer
          className="asset-class-card"
          onClick={redirectToClassPage}
          onMouseEnter={() => {
            setHoverStyle({
              display: 'block'
            });
          }}
          onMouseLeave={() => {
            setHoverStyle({display: 'none'});
          }}
        >
          <div className="allocation-donut-graph" style={hoverStyle}>
            <DonutGraph
              assetAllocationData={assetCardGraphData}
              graphType="asset-card-donut-graph"
              assetClassName={assetCardData?.assetClass}
            />
          </div>
          <h4 data-asset-class={assetCardData?.assetClass}>{assetCardData?.class}</h4>
          <h2>{formatDollar(assetCardData?.marketValue)}</h2>
          <div className="body-medium-bold card-text">
            <div className="text-spacing">
              {formatPercent(assetCardData?.gainsPercent, true)}{' '}
              {formatDollar(assetCardData?.gains, true)}
            </div>
          </div>
          {assetCardData.report === null ? (
            ''
          ) : (
            <div data-testid={`${assetCardData?.assetClass}-cardgraph`}>
              <LineGraph
                reportData={assetCardData?.report?.data}
                type="line-graph"
                testingInception={testingInception}
              />
            </div>
          )}
        </AssetClassCardContainer>
      );
    case 'Private Equity / Venture Capital':
      return (
        <AssetClassCardContainer
          className="asset-class-card-container"
          onClick={redirectToClassPage}
          onMouseEnter={() => {
            setHoverStyle({
              display: 'block'
            });
          }}
          onMouseLeave={() => {
            setHoverStyle({display: 'none'});
          }}
        >
          <div className="allocation-donut-graph" style={hoverStyle}>
            <DonutGraph
              assetAllocationData={assetCardGraphData}
              graphType="asset-card-donut-graph"
              assetClassName={assetCardData?.assetClass}
            />
          </div>
          <h4 data-asset-class={assetCardData?.assetClass}>{assetCardData?.class}</h4>
          <h2>{formatDollar(assetCardData?.marketValue)}</h2>
          <div className="body-medium-bold card-text">
            IRR: {formatPercent(assetCardData.irr, true)} / MOC: {formatMoc(assetCardData?.moc)}
            <br />
            Unfunded: {formatDollarsInMillionsWithDecimal(assetCardData?.totalFundingUnfunded)}
          </div>
          {R.isNil(assetCardData?.report?.data) || R.isEmpty(assetCardData?.report?.data) ? (
            NA
          ) : (
            <div data-testid={`${assetCardData?.assetClass}-cardgraph`}>
              <DistCCGraph
                reportData={formatDistCCGraphXAxisAssetCard(
                  sortedAssetCardReportData,
                  activePeriod,
                  clientInceptionDate,
                  clientInfo.selectedAsOfDate
                )}
                testingWidth={testingWidth}
                type="assetCard-graph"
                displayMarketValue={false}
                period={activePeriod}
              />
            </div>
          )}
        </AssetClassCardContainer>
      );
    case 'Real Assets':
      return (
        <AssetClassCardContainer
          className="asset-class-card"
          onClick={redirectToClassPage}
          onMouseEnter={() => {
            setHoverStyle({
              display: 'block'
            });
          }}
          onMouseLeave={() => {
            setHoverStyle({display: 'none'});
          }}
        >
          <div className="allocation-donut-graph" style={hoverStyle}>
            <DonutGraph
              assetAllocationData={assetCardGraphData}
              graphType="asset-card-donut-graph"
              assetClassName={assetCardData?.assetClass}
            />
          </div>
          <h4 data-asset-class={assetCardData?.assetClass}>{assetCardData?.class}</h4>
          <h2>{formatDollar(assetCardData?.marketValue)}</h2>
          <div className="body-medium-bold card-text">
            IRR: {formatPercent(assetCardData?.irr, true)} / MOC: {formatMoc(assetCardData?.moc)}
            <br />
            Unfunded: {formatDollarsInMillionsWithDecimal(assetCardData?.totalFundingUnfunded)}
            {R.isNil(assetCardData?.report?.data) || R.isEmpty(assetCardData?.report?.data) ? (
              NA
            ) : (
              <div data-testid={`${assetCardData?.assetClass}-cardgraph`}>
                <DistCCGraph
                  reportData={formatDistCCGraphXAxisAssetCard(
                    sortedAssetCardReportData,
                    activePeriod,
                    clientInceptionDate,
                    clientInfo.selectedAsOfDate
                  )}
                  testingWidth={testingWidth}
                  type="assetCard-graph"
                  displayMarketValue={false}
                  period={activePeriod}
                />
              </div>
            )}
          </div>
        </AssetClassCardContainer>
      );
    default:
      return null;
  }
};

AssetClassCard.propTypes = {
  testingWidth: PropTypes.number,
  assetCardData: PropTypes.shape({
    key: PropTypes.string.isRequired,
    assetClass: PropTypes.string,
    class: PropTypes.string,
    marketValue: PropTypes.number.isRequired,
    netflows: PropTypes.number,
    gainsPercent: PropTypes.number,
    gains: PropTypes.number,
    irr: PropTypes.number,
    moc: PropTypes.number,
    totalFundingUnfunded: PropTypes.number,
    accounts: PropTypes.array,
    displayName: PropTypes.string,
    report: PropTypes.shape({
      data: PropTypes.array
    })
  }),
  assetCardGraphData: PropTypes.array,
  testingInception: PropTypes.number
};
export default AssetClassCard;
