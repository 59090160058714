/* eslint-disable-next-line no-unused-vars */
import * as R from 'ramda';

import {classOrder, classDisplayNames, classLocations} from './assetClassProperties.data';
import * as COLORS from '../theme/colors';
// import {tapLog} from '.';

// Suggested array of objects containing all the information
// related to assetClasses... more can be added as needed and support with a function
// For now, the original objects are in ./assetClassProperties.data so that they can
// be used for testing as well.
/* eslint-disable-next-line  no-unused-vars */
const assetClassOrderAndProperties = [
  {
    cash: {
      location: '/cashandfixedPage',
      displayName: 'Cash',
      categoryName: 'Cash',
      colorName: '@cash-asset-category',
      colorCode: COLORS['@cash-asset-category']
    }
  },
  {
    fixedIncome: {
      location: '/cashandfixedPage',
      displayName: 'Fixed Income',
      categoryName: 'Fixed',
      colorName: '@fixed-asset-category',
      colorCode: COLORS['@fixed-asset-category']
    }
  },
  {
    balancedAccounts: {
      location: '/balancedaccountsPage',
      displayName: 'Balanced Accounts',
      categoryName: 'Balanced',
      colorName: '@balanced-asset-category',
      colorCode: COLORS['@balanced-asset-category']
    }
  },
  {
    equities: {
      location: '/equitiesPage',
      displayName: 'Equities',
      categoryName: 'Equities',
      colorName: '@equities-asset-category',
      colorCode: COLORS['@equities-asset-category']
    }
  },
  {
    alternative: {
      location: '/alternativesPage',
      displayName: 'Alternative Assets',
      categoryName: 'Alternatives',
      colorName: '@alternatives-asset-category',
      colorCode: COLORS['@alternatives-asset-category']
    }
  },
  {
    otherInvestments: {
      location: '/otherinvestmentsPage',
      displayName: 'Other Investments',
      categoryName: 'Other',
      colorName: '@other-investments-category',
      colorCode: COLORS['@other-investments-category']
    }
  },
  {
    hybrid: {
      location: '/hybridinvestmentsPage',
      displayName: 'Hybrid Investments',
      categoryName: 'Hybrid',
      colorName: '@hybrid-investments-category',
      colorCode: COLORS['@hybrid-investments-category']
    }
  },
  {
    multiAsset: {
      location: '/multiassetinvestmentsPage',
      displayName: 'Multi-Asset Investments',
      categoryName: 'Multi',
      colorName: '@multi-asset-investments-category',
      colorCode: COLORS['@multi-asset-investments-category']
    }
  },
  {
    privateEquities: {
      location: '/privateequitiesPage',
      displayName: 'Private Equity / Venture Capital',
      categoryName: 'PE/VC',
      colorName: '@pe/vc-asset-category',
      colorCode: COLORS['@pe/vc-asset-category']
    }
  },
  {
    realAssets: {
      location: '/realassetsPage',
      displayName: 'Real Assets',
      categoryName: 'RA',
      colorName: '@real-asset-category',
      colorCode: COLORS['@real-asset-category']
    }
  },
  {
    'Real Estate': {
      location: '/realassetsPage',
      displayName: 'Real Estate',
      categoryName: 'RA',
      colorName: '@real-asset-category',
      colorCode: COLORS['@real-asset-category']
    }
  },
  {
    'Hard Assets': {
      location: '/realassetsPage',
      displayName: 'Hard Assets',
      categoryName: 'RA',
      colorName: '@real-asset-category',
      colorCode: COLORS['@real-asset-category']
    }
  }
];

const mergedAssetClassProperties = R.mergeAll(assetClassOrderAndProperties);

export const assetClassOrder = () => classOrder;

export const assetClassDisplayName = asset => classDisplayNames[asset];

export const assetClassLocation = asset => classLocations[asset];

export const assetClassPathNames = () =>
  R.compose(R.pluck('location'), R.values)(mergedAssetClassProperties);

export const assetClassCategoryNames = () =>
  R.compose(R.pluck('categoryName'), R.values)(mergedAssetClassProperties);

export const assetClassDisplayNames = () =>
  R.compose(R.pluck('displayName'), R.values)(mergedAssetClassProperties);

/**
 * @function assetClassProperties
 * @param {string} assetClassCode -- the index into assetClassOrderAndProperties
 * @returns {object} the object for that assetClass with the items 'code' added
 */
export const assetClassProperties = asset =>
  R.assoc('code', asset, R.values(R.pick([asset], mergedAssetClassProperties))[0]);

export const assetClassCategoryColors = () =>
  R.compose(R.pluck('colorName'), R.values)(mergedAssetClassProperties);
