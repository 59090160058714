import React, {useState, useEffect, useContext} from 'react';
import * as R from 'ramda';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import {useQuery} from '@apollo/client';

/* eslint-disable-next-line no-unused-vars */
import {AppContext, appActionTypes} from '../../context/AppContext';
import {GET_ADMIN} from '../../graphql/queries/getAdmin';
import {setLoadingCountHeader} from '../../utils/utilHelpers';
import logger from '../../logger';

const StyledDisclosureText = styled.div``;

const StyledHomeText = styled.div``;

const StyledGeneralDisclosureText = styled.div`
  padding-top: 50px;
  padding-bottom: 60px;
`;

const HOME_PAGE_DISCLOSURE_TEXT_1 =
  'The CDE is intended to provide portfolio information on an accelerated basis and is not intended to replace the formalized, final reporting materials prepared by Pathstone for its clients. As such, the information presented is subject to change and may not include the same level of detail as found in formal client reporting.';

const HOME_PAGE_DISCLOSURE_TEXT_2 =
  'Performance presented at the asset class or single investment level is calculated net of underlying manager fees and gross of Pathstone advisory fees. Portfolio-level performance is presented net of underlying manager fees and either gross or net of Pathstone advisory fees based on client-specific reporting requirements. Please refer to formal client reporting materials for detail regarding the portfolio-level performance calculations for the presented portfolio. Past performance is not a guarantee of future results. Please refer to the Additional Disclosures tab for more information.';

const PERFORMANCE_PAGE_DISCLOSURE_TEXT_1 =
  'Portfolio returns are presented net of underlying manager fees and gross of Pathstone fees, unaudited, and may contain estimates. The portfolio assets for which performance is presented reflect the asset base on which Pathstone calculates returns in the formal client reporting materials for the applicable client portfolio. In some cases, these assets may include a combination of assets that Pathstone deems to be assets under management (“AUM”) and assets that Pathstone does not consider to be AUM but reports upon at the request of the client. Please refer to your formal client reporting materials for a list of the assets that Pathstone considers to be legacy, client directed, or otherwise non-AUM.';

const PERFORMANCE_PAGE_DISCLOSURE_TEXT_2 =
  'The asset class reference indices included herein have been provided as reference points and are broad-based securities market indices that are unmanaged. Broad-based securities market indices are not subject to fees and expenses. Investments cannot be made directly in a broad-based securities index. Comparison of a client’s asset class returns to the various indices is for illustrative purposes only and actual performance may be impacted by differences in underlying holdings, performance, volatility, fees, taxes, and other factors. The underlying holdings of asset classes, funds, and accounts managed or advised by Pathstone deviate materially from the composition of the indices to which they are compared.';

const HOME_AND_ASSET_CLASS_PAGE_DISCLOSURE_TEXT =
  'The blended reference indices were included to provide high-level or generic, balanced allocation market performance comparisons, and are provided for illustrative reference purposes only. These blended indices do not reflect the target allocations, policy-defined indices (where applicable), or general blended indices to which the presented portfolio performance is compared in formal client reporting, and are subject to change. Please refer to formal client reporting materials for comparisons of portfolio-level performance to any policy-defined or customized reference indices.';

const GENERAL_DISCLOSURES_TEXT_P1 =
  'Performance for each Portfolio is calculated on a dollar-weighted basis (discounted cash flow methodology) using portfolio accounting software and market values of investments obtained from bank, brokerage, custodian or mutual fund statements or, in the case of partnership structures, as determined and reported by the investment manager. In some cases, investment market values may not be available as of the date shown as of when this report was compiled. In these instances, investment managers’ “composite returns” may have been used to calculate investment returns for periods subsequent to the most recent capital statement. Actual performance may vary slightly from the investment manager composites. Pathstone has not audited or independently verified information provided by investment managers.';

const GENERAL_DISCLOSURES_TEXT_P2 =
  'Portfolio performance is generally reported net of the underlying managers’ management fees and performance allocations, if any, based on information provided by such managers. Portfolio performance presented for individual investments and asset classes is gross of Pathstone’s management fee and incentive allocation, where applicable. Unless otherwise indicated or requested by the client, portfolio-level performance is presented net of underlying manager fees, as described above, and gross of Pathstone advisory fees. Where specifically indicated or requested, portfolio-level performance is presented net of Pathstone advisory fees.';

const GENERAL_DISCLOSURES_TEXT_P3 =
  'Please refer to formal client reporting materials related to the applicable portfolio(s) for additional detail. Where applicable, performance figures reflect the reinvestment of dividend​s and other earnings that are retained in any portfolios included in this report. Past performance is not a guarantee of future results.';

const REFRESH_DATE_TEXT = (_refreshDate, _refreshTime) =>
  `Portfolio information last updated on ${_refreshDate} at ${_refreshTime} US Pacific Time.`;

const DisclosureText = ({page}) => {
  const {state, dispatch} = useContext(AppContext);
  const [dataValid, setDataValid] = useState(true);

  // We must check for nil parameters because date/time are not required by graphQL/API
  const isNotNil = R.complement(R.isNil);
  const renderRefreshData = (_date, _time) => {
    if (dataValid && isNotNil(_date) && isNotNil(_time)) {
      return <div>{REFRESH_DATE_TEXT(_date, _time)}</div>;
    }
    logger.error({method: 'renderRefreshData - one or both of refreshDate / refreshTime are null'});
    return null;
  };

  const setRefreshData = data => {
    dispatch({type: appActionTypes.SET_REFRESH_DATE, payload: data.data.creationDate});
    dispatch({type: appActionTypes.SET_REFRESH_TIME, payload: data.data.creationTime});
    dispatch({type: appActionTypes.SET_REFRESH_ZONE, payload: data.data.timezone});
  };

  const {loading, error, data} = useQuery(GET_ADMIN, {
    onCompleted: setRefreshData,
    returnPartialData: true,
    fetchPolicy: 'network-only',
    errorPolicy: 'all'
  });

  useEffect(() => {
    if (loading) {
      setLoadingCountHeader({key: 'Footer/disclosuresText/GET_ADMIN', value: 1}, dispatch);
    } else if (!loading) {
      setLoadingCountHeader({key: 'Footer/disclosuresText/GET_ADMIN', value: 0}, dispatch);
    }
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    logger.error({method: `GET_ADMIN: ${error.message}`});
    setDataValid(false);
    logger.error({method: 'GET_ADMIN: Cannot access admin/refresh data'});
  }
  if (R.isNil(data) || R.isNil(data?.data)) {
    logger.error({method: 'GET_ADMIN: No admin/refresh data returned'});
    setDataValid(false);
  }
  const refreshData = data?.data;
  if (R.isNil(refreshData)) {
    logger.error({method: 'GET_ADMIN: refreshData is null'});
    setDataValid(false);
  }

  switch (page) {
    case 'coverPage':
      return (
        <StyledHomeText>
          <div>{HOME_PAGE_DISCLOSURE_TEXT_1}</div>
          <br />
          <div>{HOME_PAGE_DISCLOSURE_TEXT_2}</div>
          <br />
          <div>{HOME_AND_ASSET_CLASS_PAGE_DISCLOSURE_TEXT}</div>
          <br />
          <div>{renderRefreshData(state.refreshDate, state.refreshTime)}</div>
        </StyledHomeText>
      );
    case 'portfolioPage':
      return (
        <StyledDisclosureText>
          <div>{PERFORMANCE_PAGE_DISCLOSURE_TEXT_1}</div>
          <br />
          <div>{PERFORMANCE_PAGE_DISCLOSURE_TEXT_2}</div>
          <br />
          <div>{renderRefreshData(state.refreshDate, state.refreshTime)}</div>
        </StyledDisclosureText>
      );
    case 'AlternativesPage':
    case 'BalancedAccountsPage':
    case 'EquitiesPage':
    case 'MultiAssetPage':
      return (
        <StyledDisclosureText>{HOME_AND_ASSET_CLASS_PAGE_DISCLOSURE_TEXT}</StyledDisclosureText>
      );
    case 'DisclosurePage':
      return (
        <StyledGeneralDisclosureText className="side-padding-50 body-large">
          <div>{GENERAL_DISCLOSURES_TEXT_P1}</div>
          <br />
          <div>{GENERAL_DISCLOSURES_TEXT_P2}</div>
          <br />
          <div>{GENERAL_DISCLOSURES_TEXT_P3}</div>
        </StyledGeneralDisclosureText>
      );
    default:
      return <div>DisclosureText: No page specified</div>;
  }
};

DisclosureText.propTypes = {
  page: PropTypes.string
};

export {
  HOME_PAGE_DISCLOSURE_TEXT_1,
  HOME_PAGE_DISCLOSURE_TEXT_2,
  PERFORMANCE_PAGE_DISCLOSURE_TEXT_1,
  PERFORMANCE_PAGE_DISCLOSURE_TEXT_2,
  HOME_AND_ASSET_CLASS_PAGE_DISCLOSURE_TEXT,
  GENERAL_DISCLOSURES_TEXT_P1,
  GENERAL_DISCLOSURES_TEXT_P2,
  GENERAL_DISCLOSURES_TEXT_P3,
  REFRESH_DATE_TEXT
};

export default DisclosureText;
