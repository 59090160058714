module.exports = {
  '@primary-color': '#005CB9', // Saphire
  '@primary-navy': '#002554', // PS Navy

  '@header-text': '#FFFFFF',
  '@header-background': '#002554', // PS Navy
  '@page-color': '#FFFFFF',

  '@body-text': '#262626',
  '@body-background': '#FAFAFA',
  '@component-background': '#FFFFFF',

  '@checkbox-border': '#BFBFBF',

  '@secondary-blue': '#005CB9',
  '@secondary-green': '#3C9DFF',
  '@secondary-darkgreen': '#03935F',
  '@secondary-red': '#AD0202',

  '@cash-asset-category': '#3C9DFF',
  '@fixed-asset-category': '#005CB9',
  '@category-blue': '#ADD1F3',
  '@balanced-asset-category': '#D89D95',
  '@equities-asset-category': '#622B24',
  '@alternatives-asset-category': '#ADD1F3',
  '@category-red': '#8F0852',
  '@other-investments-category': '#7F7F7F',
  '@category-dark-pink': '#476857',
  '@hybrid-investments-category': '#476857',
  '@category-pink': '#82235F',
  '@multi-asset-investments-category': '#82235F',
  '@pe/vc-asset-category': '#8FD83A',
  '@real-asset-category': '#B9DBE5',

  '@sequential-1': '#FFFFE6',
  '@sequential-2': '#FFF7BF',
  '@sequential-3': '#FEE496',
  '@sequential-4': '#B9DBE5',
  '@sequential-5': '#FE9B3A',
  '@sequential-6': '#005CB9',
  '@sequential-7': '#CC4F1A',
  '@sequential-8': '#993613',
  '@sequential-9': '#66260D',
  '@sequential-10': '#3A1C10',

  '@neutrals-1': '#FFFFFF',
  '@neutrals-2': '#FAFAFA',
  '@neutrals-3': '#F0F0F0',
  '@neutrals-4': '#D9D9D9',
  '@neutrals-5': '#BFBFBF',
  '@neutrals-6': '#8C8C8C',
  '@neutrals-7': '#595959',
  '@neutrals-8': '#434343',
  '@neutrals-9': '#262626',
  '@neutrals-10': '#000000',

  '@text-color': '#262626',
  '@benchmark1-dark-grey': '#525252',
  '@benchmark2-light-grey': '#a5a5a5'
};
